export default class KidsHeightWeight {

    constructor(idKid,fullName,gender, height = 0, weight = 0, birthDay,measureDay) {
        this.idKid = idKid;
        this.fullName = fullName;
        this.gender = gender;
        this.height = height;
        this.weight = weight;
        this.birthDay = birthDay;
        this.measureDay = measureDay;
    }
}
