<template>
  <div class="content">
    <!-- add -->
    <div style="margin-bottom: -20px; position: relative; z-index: 1">
      <div v-if="showAdd">
        <el-date-picker
            class="button-left-class1"
            v-model="dataAddSearch.date"
            type="date"
            :clearable="false"
            format="dd/MM/yyyy"
            @change="getAllKidHeightWeight('date')"
            :disabled="checkDisable"
            value-format="yyyy-MM-dd"
            placeholder="Chọn ngày"
            :picker-options="pickerOptions"
        ></el-date-picker>

        <el-select
            class="button-left-status"
            v-model="dataAddSearch.status"
            @change="getAllKidHeightWeight()"
            placeholder="Trạng thái"
        >
          <el-option
              v-for="item in kidStatusList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
          ></el-option>
        </el-select>

        <!-- chọn khối -->
        <el-select
            v-if="getAppTypeUserLogin == 'plus'"
            class="input-common input-grade"
            style="width: 140px"
            v-model="dataAddSearch.idGrade"
            @change="changeGradeDetail()"
            placeholder="Chọn khối"
        >
          <el-option
              v-for="item in gradeOfSchoolList"
              :key="item.id"
              :value="item.id"
              :label="item.gradeName"
          ></el-option>
        </el-select>

        <!-- chọn lớp -->
        <el-select
            class="input-common input-class"
            style="width: 140px; margin-left: 5px; margin-right: 5px"
            v-model="dataAddSearch.idClass"
            placeholder="Chọn lớp"
            @change="getAllKidHeightWeight('date')"
        >
          <el-option
              v-for="item in classList"
              :key="item.id"
              :value="item.id"
              :label="item.className"
          ></el-option>
        </el-select>

        <el-input
            class="button-left-class"
            style="width: 220px"
            placeholder="Nhập tên"
            clearable
            v-model="dataAddSearch.codeOrName"
            @change="getAllKidHeightWeight()"
            @keyup.enter.native="getAllKidHeightWeight()"
        >
          <el-button
              slot="append"
              icon="el-icon-search"
              @click="getAllKidHeightWeight()"
          ></el-button>
        </el-input>
      </div>
      <!-- history -->
      <div
          v-else-if="showHistory"
          style="margin-bottom: -20px; position: relative; z-index: 1"
      >
        <el-date-picker
            class="button-left-class1"
            v-model="selectDate"
            type="date"
            :disabled="checkDisable"
            placeholder="Chọn ngày"
            :picker-options="pickerOptions"
        >
          >
        </el-date-picker
        >

        <el-select
            class="button-left-status"
            v-model="dataSearch.status"
            @change="getAllKidHeightWeightHistory()"
            placeholder="Trạng thái"
        >
          <el-option
              v-for="item in kidStatusList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
          ></el-option>
        </el-select>

        <!-- chọn khối -->
        <el-select
            v-if="getAppTypeUserLogin == 'plus'"
            class="input-common input-grade"
            style="width: 140px"
            v-model="dataSearch.idGrade"
            @change="changeGradeDetailH()"
            placeholder="Chọn khối"
        >
          <el-option
              v-for="item in gradeOfSchoolList"
              :key="item.id"
              :value="item.id"
              :label="item.gradeName"
          ></el-option>
        </el-select>

        <!-- chọn lớp -->
        <el-select
            class="input-common input-class"
            style="width: 140px; margin-left: 5px; margin-right: 5px"
            v-model="dataSearch.idClass"
            placeholder="Chọn lớp"
            @change="getAllKidHeightWeightHistory()"
        >
          <el-option
              v-for="item in classList"
              :key="item.id"
              :value="item.id"
              :label="item.className"
          ></el-option>
        </el-select>

        <el-input
            class="button-left-clas"
            style="width: 220px"
            placeholder="Nhập mã hoặc tên"
            clearable
            v-model="dataSearch.codeOrName"
            @change="getAllKidHeightWeightHistory()"
            @keyup.enter.native="getAllKidHeightWeightHistory()"
        >
          <el-button
              slot="append"
              icon="el-icon-search"
              @click="getAllKidHeightWeightHistory()"
          ></el-button>
        </el-input>


      </div>
    </div>

    <div class="lblthemlichsu">
      <el-tabs type="card" v-model="activeName" @tab-click="handleClickTab">
        <el-tab-pane label="Thêm cân đo" name="AddHeightWeight">
          <!-- table -->
          <div class="table-content row-data">
            <el-table
                ref="multipleTable"
                :empty-text="textTable"
                v-loading="loadingData"
                :element-loading-text="$tableLoading"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255,255,255, 0)"
                :data="AddHeightWeightList"
                highlight-current-row
                :header-cell-style="tableHeaderColor"
                @selection-change="handleSelectionChange"
                :max-height="$tableMaxHeight"
                border
            >
              <el-table-column
                  align="center"
                  type="selection"
                  width="55"
              ></el-table-column>
              <el-table-column
                  type="index"
                  label="STT"
                  width="50"
                  align="center"
              ></el-table-column>
              <el-table-column
                  prop="fullName"
                  label="Họ và tên"
                  align="left"
              ></el-table-column>
              <el-table-column label="Ngày sinh" width="140" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.birthDay | formatDate }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="weight" label="Cân nặng " align="center">
                <template slot-scope="scope">
                  <el-input
                      v-model="scope.row.weight"
                      style="width: 60px; border: 1px solid #ffffff !important"
                      placeholder="......."
                      value
                  ></el-input
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Kg
                </template>
              </el-table-column>

              <el-table-column label="Ngày cân " align="center">
                <template slot-scope="scope">
                  <el-date-picker
                      class="button-left-class3"
                      v-model="scope.row.timeWeight"
                      type="date"
                      align="center"
                      placeholder="Chọn ngày"
                      value-format="yyyy-MM-dd"
                      format="dd/MM/yyyy"
                      :picker-options="pickerOptions"
                  ></el-date-picker>
                </template>
              </el-table-column>

              <el-table-column prop="height" label="Chiều cao" align="center">
                <template slot-scope="scope">
                  <el-input
                      class="button-left-class0"
                      v-model="scope.row.height"
                      style="width: 60px"
                      placeholder="......."
                  ></el-input
                  >
                  Cm
                </template>
              </el-table-column>
              <el-table-column label="Ngày đo " align="center">
                <template slot-scope="scope">
                  <el-date-picker
                      class="button-left-class9"
                      v-model="scope.row.timeHeight"
                      type="date"
                      placeholder="Chọn ngày"
                      value-format="yyyy-MM-dd"
                      format="dd/MM/yyyy"
                      :picker-options="pickerOptions"
                  ></el-date-picker>
                </template>
              </el-table-column>

              <el-table-column
                  label="Tác vụ"
                  width="160"
                  align="center"
                  fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                      v-if="
                      checkPermission(['studentQuality_heightWeight_update'])
                    "
                      size="mini"
                      type="success"
                      @click="handleSave(scope.row)"
                  >Lưu
                  </el-button>

                  <el-button
                      size="mini"
                      @click="handleViewDetail(scope.$index, scope.row)"
                      type="primary"
                  >Chi tiết
                  </el-button>
                </template>

              </el-table-column>
            </el-table>
            <div class="btt">

              <el-dropdown @command="handleCommandHeightWeight">
                <el-button class="button-over" type="success" :loading="loadingButton">
                  Xuất file Excel đánh giá &nbsp;&nbsp;
                  <i class="el-icon-caret-bottom"/>
                </el-button>

                <el-dropdown-menu class="el-dropdown-menu-container">
                  <el-dropdown-item command="exportExcelRecentTimes">Xuất theo ngày cân mới nhất</el-dropdown-item>
                  <el-dropdown-item command="exportExcelSelectTime">Xuất theo khoảng thời gian</el-dropdown-item>
                  <el-dropdown-item command="exportExcelStatisticalToSheet">Thống kê đánh giá từng HS
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

              <el-dropdown @command="handleCommand">
                <el-button class="button-over" type="success">
                  Tác vụ
                  <i class="el-icon-caret-bottom"/>
                </el-button>
                <el-dropdown-menu class="el-dropdown-menu-container">
                  <el-dropdown-item command="exportExcelAll"
                  >Xuất các học sinh
                  </el-dropdown-item
                  >
                  <el-dropdown-item command="exportExcelNew"
                  >Xuất mới nhất trong tháng
                  </el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <!-- -- -->
        </el-tab-pane>

        <!-- lich su -->
        <el-tab-pane label="Lịch sử cân đo" name="HistoryHeightWeight">
          <div class="table-content row-data">
            <el-table
                :empty-text="textTable"
                v-loading="loadingData"
                :element-loading-text="$tableLoading"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255,255,255, 0)"
                :data="kidsHeightWeightHistoryList"
                @selection-change="handleSelectionChange"
                highlight-current-row
                :header-cell-style="tableHeaderColor"
                :max-height="$tableMaxHeight"
                border
            >
              <el-table-column
                  align="center"
                  type="selection"
                  width="55"
              ></el-table-column>
              <el-table-column
                  type="index"
                  label="STT"
                  width="50"
                  align="center"
              ></el-table-column>
              <el-table-column
                  prop="fullName"
                  label="Họ và tên"
                  align="left"
              ></el-table-column>

              <el-table-column
                  prop="weight"
                  label="Cân nặng (Kg)"
                  align="center"
              ></el-table-column>
              <el-table-column
                  prop="timeWeight"
                  label="Ngày cân mới nhất"
                  width="160"
                  align="center"
              ></el-table-column>
              <el-table-column
                  prop="height"
                  label="Chiều cao (Cm)"
                  align="center"
              ></el-table-column>

              <el-table-column
                  prop="timeHeight"
                  label="Ngày đo mới nhất"
                  width="160"
                  align="center"
              ></el-table-column>

              <el-table-column
                  label="Tác vụ"
                  width="150"
                  align="center"
                  fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                      class="buttonxemchitiet"
                      size="mini"
                      type="primary"
                      @click="handleViewDetail(scope.$index, scope.row)"
                  >Chi tiết
                  </el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="btt">
              <el-button
                  class="button-over"
                  type="success"
                  :loading="loadingButton"
                  @click="exportExcelKidsHeightWeightNew()"
              >Xuất file Excel
              </el-button
              >
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!--    confirm date-->
    <el-dialog
        title="Vui lòng chọn tháng"
        :visible.sync="dialogVisibleSelectMonth"
        width="30%"
        :before-close="handleClose">
      <div>
        <el-date-picker
            format="MM-yyyy"
            style="width: 200px"
            v-model="monthAndYear"
            type="monthrange"
            align="right"
            unlink-panels
            @change="callDataSearch"
            range-separator="-"
            start-placeholder="Tháng bắt đầu"
            :clearable="false"
            end-placeholder="Tháng kết thúc">
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="confirmSelectMonth">OK</el-button>
    <el-button type="primary" @click="cancelSelectMonth">Cancel</el-button>
  </span>
    </el-dialog>
    <KidsHeightWeightDetail
        :dialogVisible="showCreateDialog"
        @dialog-close="dialogCloseShowMethod()"
        ref="KidsHeightWeightDetail"
    />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission.js";
import KidsHeightWeightDetail from "./KidsHeightWeightDetail.vue";
import KidsHeightWeightService from "@/services/KidsService/KidsHeightWeightService";
import moment from "moment";
import {kidStatusList} from "@/constant/listCommon";
import {mapGetters, mapState} from "vuex";
import {exportToExcel} from "@/commonFuncion/ExportExcelForHeightWeight";
import {bmiBoy, bmiGirl} from "@/constant/bmiIndexBoyAndGirl";
import KidsHeightWeight from "@/models/kidsHeightWeight";
import {exportExcelToSheet} from "@/commonFuncion/ExportExcelToSheet";

export default {
  components: {
    KidsHeightWeightDetail,
  },
  data() {
    return {
      loadingData: true,
      textTable: "",
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      kidStatusList,
      activeName: "AddHeightWeight",
      showAdd: true,
      showHistory: false,
      pageTotal: this.$pageTotalDefault,
      pageSize: this.$pageSizeDefault,
      showCreateDialog: false,
      multipleSelection: [],
      kidsHeightWeightHistoryList: [],
      AddHeightWeightList: [],
      checkDisable: false,
      value2: "",
      selectDate: "",
      value3: "",
      value4: "",
      value5: "",
      position: "",
      position1: "",
      newBrandCreated: null,
      newFeedbackUpdate: null,
      // -------
      value: "",
      dataAddSearch: {
        date: "",
        status: "STUDYING",
        idGrade: "",
        idClass: "",
        codeOrName: "",
      },
      loadingButton: false,
      dataSearch: {
        status: "STUDYING",
        idGrade: "",
        idClass: "",
        codeOrName: "",
      },
      maClassList: [],
      newHeightWeight: {
        weight: "",
        timeWeight: "",
        height: "",
        timeHeight: "",
      },
      //css excel
      styleCols1: [],
      styleCols2: [],
      kidsListHistoryHeightWeight: [],
      allHeightData: [],
      allHeightDataBoy: [],
      allHeightDataGirl: [],
      allWeightData: [],
      allWeightDataBoy: [],
      allWeightDataGirl: [],
      exportToExcelData: {
        titleFile: 'DANH SÁCH CHIỀU CAO, CÂN NẶNG, CHỈ SỐ BMI',
        columnHeaders: // Đầu mục của các cột
            ['STT', 'Họ và tên', 'Ngày sinh', 'Tuổi(tính đến ngày cân)', 'Giới tính', 'Cân nặng', 'Chiều cao', 'Chỉ số BMI', 'Ngày cân đo gần nhất',
              // cột cân nặng
              'Suy dinh dưỡng nặng', 'Suy dinh dưỡng', 'Trẻ cân nặng bình thường', 'Trẻ thừa cân', 'Cân nặng ở mức béo phì',
              // cột chiều cao
              'Thấp còi mức độ 2', 'Thấp còi mức độ 1', 'Chiều cao bình thường', 'Cao hơn so với tuổi', 'Chiều cao vượt trội',
              // cột chỉ số BMI
              'Suy dinh dưỡng', 'Thiếu cân', 'Bình thường', 'Thừa cân', 'Béo phì'],
        columnWidths: [5, 25, 12, 15, 6, 6, 6, 6, 12, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10], // Chiều rộng của từng cột
        nameFile: 'ReportBMIHeightAndWeight'
      },
      bmiBoy,
      bmiGirl,
      kidsWeightList: [],
      kidsHeightList: [],
      kidsWeightListByMonth: [],
      kidsHeightListByMonth: [],
      targetList: [],
      dialogVisibleSelectMonth: false,
      monthAndYear: [],
      selectMonthToExportExcel: false,
      searchDateForExport: {
        startDate: '',
        endDate: '',
      }
    };
    // ----
  },
  created() {
    if (this.getAppTypeUserLogin === "plus") {
      this.$store.dispatch('gradeModule/fetchDataGradeOfSchoolList');
      this.$store.dispatch('classStore/fetchDataClassListCommon');
    }
    if (this.getAppTypeUserLogin === "teacher") {
      this.$store.dispatch('classStore/fetchDataClassInTeacher')
    }
    this.$store.dispatch('schoolStore/fetchDataSchoolData');
  },

  computed: {
    ...mapState('gradeModule', ['gradeOfSchoolList']),
    ...mapGetters('classStore', ['classInTeacher']),
    ...mapState('classStore', ['classListCommon']),
    ...mapGetters('schoolStore', ['schoolInfo']),
    schoolInfoList() {
      return this.schoolInfo;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    classList() {
      if (this.getAppTypeUserLogin === "plus") {
        if (this.activeName === "AddHeightWeight") {
          return this.classListCommon.filter((x) => x.idGrade === this.dataAddSearch.idGrade);
        } else if (this.activeName === "HistoryHeightWeight") {
          return this.classListCommon.filter((x) => x.idGrade === this.dataSearch.idGrade);
        }
      } else if (this.getAppTypeUserLogin === "teacher") {
        return this.classInTeacher;
      } else return this.classListCommon
    },
    getAppTypeUserLogin() {
      return this.$store.state.auth.user.appType;
    },

  },
  watch: {
    gradeOfSchoolList: {
      immediate: true, // Kích hoạt ngay lập tức khi component được tạo
      handler(newVal) {
        if (newVal.length > 0) {
          this.getIdGrade();
        }
      },
    },
    classList: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.getIdClass();
        }
      },
    },
  },
  methods: {
    checkPermission,
    exportToExcel,
    exportExcelToSheet,
    handleClose() {
      this.dialogVisibleSelectMonth = false;
    },
    getIdGrade() {
      this.dataSearch.idGrade = this.gradeOfSchoolList[0].id;
      this.dataAddSearch.idGrade = this.gradeOfSchoolList[0].id;
    },
    getIdClass() {
      this.dataSearch.idClass = this.classList[0].id;
      this.dataAddSearch.idClass = this.classList[0].id;
      if (this.activeName === "AddHeightWeight") {
        this.getAllKidHeightWeight("first");
      } else if (this.activeName === "HistoryHeightWeight") {
        this.getAllKidHeightWeightHistory();
      }

    },
    callDataSearch() {
      this.searchDateForExport.startDate = moment(this.monthAndYear[0]).format('M-yyyy');
      this.searchDateForExport.endDate = moment(this.monthAndYear[1]).format('M-yyyy');
    },
    dataClass() {
      const foundClass = this.classList.find((x) => x.id === this.dataAddSearch.idClass);
      if (foundClass) {
        return foundClass.className;
      }
      return "";
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCommand(command) {
      if (command === "exportExcelNew") {
        this.exportExcelKidsHeightWeightNew();
      } else if (command === "exportExcelAll") {
        this.exportExcelKidsHeightWeightAll();
      }
    },
    confirmSelectMonth() {
      this.dialogVisibleSelectMonth = false;
      this.selectMonthToExportExcel = true;
      this.exportToExcelCurrent();
    },
    cancelSelectMonth() {
      this.dialogVisibleSelectMonth = false;
    },
    handleCommandHeightWeight(command) {
      if (command === "exportExcelRecentTimes") {
        this.exportToExcelCurrent();
      } else if (command === "exportExcelSelectTime") {
        this.dialogVisibleSelectMonth = true;
      } else if (command === "exportExcelStatisticalToSheet") {
        this.exportExcelStatisticalToSheet();
      }
    },
    exportExcelKidsHeightWeightNew() {
      let list = [];
      this.multipleSelection.forEach((x) => list.push(x.id));
      if (list.length == 0) {
        this.$message({
          message: "Không có học sinh nào được chọn",
          type: "error",
        });
        return;
      }
      this.$confirm("Bạn có chắc chắn muốn xuất file không?", "Thông báo!", {
        distinguishCancelAndClose: true,
        closeOnClickModal: false,
        confirmButtonText: "Có",
        cancelButtonText: "Không",
      }).then(() => {
        this.getStyleExcelNew();
        let myDate = moment().format("DD-MM-YYYY hh:mm:ss");
        KidsHeightWeightService.getExportExcelNew(list, this.dataAddSearch.idClass, this.dataAddSearch.date)
            .then((resp) => {
              import("@/services/ExportExcel")
                  .then((excel) => {
                    let dataList = resp.data.data;
                    let fileName = `BANG_KE_SO_ĐO_CHIEU_CAO_CAN_NANG_MOI_NHAT_${myDate}`;
                    const merges = ["A5:F5"];
                    const tHeader = [
                      "STT",
                      "Họ tên",
                      "Ngày cân",
                      "Cân nặng (Kg)",
                      "Ngày đo",
                      "Chiều cao (Cm)",
                    ];
                    const filterVal = ["pro1", "pro2", "pro3", "pro4", "pro5", "pro6"];
                    let columnList = [
                      {col: 5},
                      {col: 25},
                      {col: 20},
                      {col: 25},
                      {col: 20},
                      {col: 25},
                    ];
                    excel.export_json_to_excel({
                      header: tHeader,
                      merges,
                      data: dataList,
                      title: filterVal,
                      filename: fileName,
                      columnsWidth: columnList,
                      styleCols1: this.styleCols1,
                      styleCols2: this.styleCols2,
                    });
                  })
                  .finally(() => {
                    this.styleCols1 = [];
                    this.styleCols2 = [];
                  });
            })
            .catch((err) => {
              console.log(err);
              this.$message({
                message: "Thất bại",
                type: "error",
              });
            });
      });
    },
    exportExcelKidsHeightWeightAll() {
      let list = [];
      this.multipleSelection.forEach((x) => list.push(x.id));

      if (list.length == 0) {
        this.$message({
          message: "Không có học sinh nào được chọn",
          type: "error",
        });
        return;
      }
      this.$confirm("Bạn có chắc chắn muốn xuất file không?", "Thông báo!", {
        distinguishCancelAndClose: true,
        closeOnClickModal: false,
        confirmButtonText: "Có",
        cancelButtonText: "Không",
      }).then(() => {
        this.getStyleExcelAll();
        let myDate = moment().format("DD-MM-YYYY hh:mm:ss");
        KidsHeightWeightService.getExportExcelWeightHeight(list)
            .then((resp) => {
              import("@/services/ExportExcel")
                  .then((excel) => {
                    let dataList = resp.data.data;
                    let fileName = `BANG_KE_SO_ĐO_CHIEU_CAO_CAN_NANG_${myDate}`;
                    const merges = ["A5:D5"];
                    const tHeader = [
                      "STT",
                      "Ngày thực hiện",
                      "Cân nặng (Kg)",
                      "Chiều cao (Cm)",
                    ];
                    const filterVal = ["pro1", "pro2", "pro3", "pro4",];
                    let columnList = [
                      {col: 5},
                      {col: 20},
                      {col: 25},
                      {col: 25},
                    ];
                    excel.export_json_to_excel({
                      header: tHeader,
                      merges,
                      data: dataList,
                      title: filterVal,
                      filename: fileName,
                      columnsWidth: columnList,
                      styleCols1: this.styleCols1,
                      styleCols2: this.styleCols2,
                    });
                  })
                  .finally(() => {
                    this.styleCols1 = [];
                    this.styleCols2 = [];
                  });
            })
            .catch((err) => {
              console.log(err);
              this.$message({
                message: "Thất bại",
                type: "error",
              });
            });
      });
    },
    //Style css ExcelAll
    getStyleExcelAll() {
      let font = this.$styleExcel.fontTitle();
      let alignment = this.$styleExcel.alignmentTitle();
      let border = this.$styleExcel.borderTitle();

      let style1 = {
        name: "A5:E5",
        style: {
          fill: {fgColor: {rgb: "d9d290"}},
          font,
          alignment,
          border,
        },
      };
      let style2 = {
        name: "A6:E6",
        style: {
          fill: {fgColor: {rgb: "f8eb00"}},
          font,
          alignment,
          border,
        },
      };
      let style3 = {
        name: "A7:E50",
        style: {
          fill: {fgColor: {rgb: "ffff74"}},
          alignment,
          font: {sz: "11"},
          border,
        },
      };

      let styleCol01 = {
        name: ["A1"],
        style: {
          font: {color: {rgb: "ff0000"}, bold: true, sz: "18"},
        },
      };
      let styleCol02 = {
        name: ["A2", "A3", "A4"],
        style: {
          font: {bold: true, sz: "11"},
        },
      };

      this.styleCols2.push(style1);
      this.styleCols2.push(style3);

      this.styleCols1.push(styleCol01);
      this.styleCols1.push(styleCol02);

      this.styleCols2.push(style2);
    },
    //Style css ExcelNew
    getStyleExcelNew() {
      let font = this.$styleExcel.fontTitle();
      let alignment = this.$styleExcel.alignmentTitle();
      let border = this.$styleExcel.borderTitle();

      let style1 = {
        name: "A5:B5",
        style: {
          fill: {fgColor: {rgb: "d9d290"}},
          font: {bold: true, sz: "11", color: {rgb: "ff0000"}},
          alignment,
          border,
        },
      };
      let style2 = {
        name: "A6:F6",
        style: {
          fill: {fgColor: {rgb: "f8eb00"}},
          font,
          alignment,
          border,
        },
      };
      let style3 = {
        name: "C5:F5",
        style: {
          fill: {fgColor: {rgb: "d9d290"}},
          font,
          alignment,
          border,
        },
      };

      let styleCol01 = {
        name: ["A1"],
        style: {
          font: {color: {rgb: "ff0000"}, bold: true, sz: "18"},
        },
      };
      let styleCol02 = {
        name: ["A2", "A3", "A4"],
        style: {
          font: {bold: true, sz: "11"},
        },
      };

      this.styleCols2.push(style1);
      this.styleCols2.push(style3);

      this.styleCols1.push(styleCol01);
      this.styleCols1.push(styleCol02);

      this.styleCols2.push(style2);
    },
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold";
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // click chọn số bản ghi trên 1 trang
    handleSizeChange(maxPageItem) {
      this.tableDataFilter = this.studentList.slice(0, maxPageItem);
      this.pageSize = maxPageItem;
      this.pageTotal = this.studentList.length;
    },

    changeDate() {
      this.AddHeightWeightList.forEach((element) => {
        element.timeHeight = this.dataAddSearch.date;
        element.timeWeight = this.dataAddSearch.date;
      });
    },
    handleClickTab(tab) {
      let tabNameClick = tab.name;
      if (tabNameClick == "AddHeightWeight") {
        this.showAdd = true;
        this.showHistory = false;
        this.checkDisable = false;
      } else if (tabNameClick == "HistoryHeightWeight") {
        this.showAdd = false;
        this.showHistory = true;
        this.checkDisable = true;
        this.getAllKidHeightWeightHistory();
      }
    },
    dialogCloseShowMethod() {
      this.showCreateDialog = false;
      this.getAllKidHeightWeightHistory();
    },
    // search history
    getAllKidHeightWeightHistory() {
      this.loadingData = true;
      this.textTable = "";
      KidsHeightWeightService.searchWeightHeight(
          "",
          this.dataSearch.status,
          this.dataSearch.idClass,
          this.dataSearch.codeOrName
      )
          .then((resp) => {
            if (this.getAppTypeUserLogin == "teacher") {
              this.kidsHeightWeightHistoryList =
                  this.dataSearch.idClass != ""
                      ? resp.data.data.kidsHeightWeightResponse
                      : [];
            } else {
              this.kidsHeightWeightHistoryList =
                  resp.data.data.kidsHeightWeightResponse;
            }
          })
          .catch((err) => {
            this.kidsHeightWeightHistoryList = null;
            console.log(err);
          })
          .finally(() => {
            if (this.kidsHeightWeightHistoryList.length == 0) {
              this.textTable = this.$tableEmpty;
            }
            this.loadingData = false;
          });
    },
    // search add
    getAllKidHeightWeight(searchType) {
      this.loadingData = true;
      this.textTable = "";
      KidsHeightWeightService.searchWeightHeight(
          this.dataAddSearch.date,
          this.dataAddSearch.status,
          this.dataAddSearch.idClass,
          this.dataAddSearch.codeOrName
      )
          .then((resp) => {
            if (this.getAppTypeUserLogin === "teacher") {
              this.AddHeightWeightList =
                  this.dataAddSearch.idClass !== ""
                      ? resp.data.data.kidsHeightWeightResponse : [];

            } else {
              this.AddHeightWeightList = resp.data.data.kidsHeightWeightResponse;
            }
          })
          .catch((err) => {
            this.AddHeightWeightList = null;
            console.log(err);
          })
          .finally(() => {
            if (searchType == "date" || searchType == "first") {
              this.changeDate();
            }
            if (this.AddHeightWeightList.length == 0) {
              this.textTable = this.$tableEmpty;
            }
            this.loadingData = false;
          });
    },
    //click save
    handleSave(row) {
      this.loadingButton = true;
      KidsHeightWeightService.create(row)

          .then((response) => {
            this.newBrandCreated = response.data.data;
            this.$message({
              message: "Lưu số đo thành công",
              type: "success",
            });

          })
          .catch((err) => {
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          })
          .finally(() => {
            setTimeout(() => {
              this.loadingButton = false;
            }, 500);
          });
    },

    //click chọn số trang
    handleCurrentChange(pageNumber) {
      let number = this.pageSize;
      this.tableDataFilter = this.studentList.slice(
          (pageNumber - 1) * number,
          pageNumber * number
      );
    },
    // click xem chi tiet
    handleViewDetail(index, row) {
      this.showCreateDialog = true;
      this.$refs.KidsHeightWeightDetail.getDetailKidsMethod(row.id);
    },

    async changeGradeDetail() {
      this.dataAddSearch.idClass = this.classList[0]
      this.getAllKidHeightWeight();
    },

    async changeGradeDetailH() {
      this.dataSearch.idClass = this.classList[0]
      this.getAllKidHeightWeightHistory();
    },

    getCurrentDate() {
      this.dataAddSearch.date = moment().format("YYYY-MM-DD");
    },

    // Hàm tính ra số tháng từ sinh nhật cho đến ngày cân
    getMonthNumber(birthDay, measureDay) {
      const formattedBirthDay = birthDay.replace(/\//g, '-');
      const formattedMeasureDay = measureDay.replace(/\//g, '-');
      const kidBirthday = new Date(formattedBirthDay);
      const date2 = new Date(formattedMeasureDay);
      const yearsDiff = date2.getFullYear() - kidBirthday.getFullYear();
      const monthsDiff = date2.getMonth() - kidBirthday.getMonth();
      return yearsDiff * 12 + monthsDiff;
    },
    // Hàm tính ra số x năm x tháng
    calculateYearsAndMonths(birthDay, measureDay) {
      const inputNumber = this.getMonthNumber(birthDay, measureDay);
      const years = Math.floor(inputNumber / 12);
      const months = inputNumber % 12;
      if (years < 1) {
        return months + ' tháng';
      }
      return years + ' năm ' + months + ' tháng';
    },
    // Lấy ra số nguyên từ chuỗi str
    parseIntFromString(strInput) {
      strInput.replace(/[^0-9]/g, "");
      return parseInt(strInput);
    },

    // lấy ra chỉ số BMI của từng bé để so sánh
    calculateMonthsOldBMI(birthDay, measureDay, bmi, gender) {
      if (bmi === 0) {
        return "Chưa đo"
      }
      const months = this.getMonthNumber(birthDay, measureDay)
      let boyMonth = {};
      let girlMonth = {};
      if (gender === "Nam") {
        if (months > 72) {
          boyMonth = this.bmiBoy[71]
        } else {
          boyMonth = this.bmiBoy.find((core) => core.month === months);
        }

        if (bmi >= boyMonth.tooHigh) {
          return "tooHigh";
        } else if (bmi >= boyMonth.high && bmi < boyMonth.tooHigh) {
          return "high";
        } else if (bmi <= boyMonth.tooLow) {
          return "tooLow";
        } else if (bmi <= boyMonth.low && bmi > boyMonth.tooLow) {
          return "low";
        } else {
          return "normal";
        }

      } else {
        if (months > 72) {
          girlMonth = this.bmiGirl[71]
        } else {
          girlMonth = this.bmiGirl.find((core) => core.month === months);
        }

        if (bmi >= girlMonth.tooHigh) {
          return "tooHigh";
        } else if (bmi >= girlMonth.high && bmi < girlMonth.tooHigh) {
          return "high";
        } else if (bmi <= girlMonth.tooLow) {
          return "tooLow";
        } else if (bmi <= girlMonth.low && bmi > girlMonth.tooLow) {
          return "low";
        } else {
          return "normal";
        }
      }

    },

    // Lấy ra cân nặng ở bảng allWeightData để so sánh
    getFromAllWeightData(birthDay, measureDay, gender, weight) {
      if (weight === 0) {
        return "Chưa đo"
      }
      const months = this.getMonthNumber(birthDay, measureDay)
      let boyMonth = {};
      let girlMonth = {};
      let self = this;
      if (gender === "Nam") {
        if (months > 72) {
          boyMonth = this.allWeightDataBoy[71];
        } else {
          boyMonth = this.allWeightDataBoy.find((core) => self.parseIntFromString(core.yearOld) === months);
        }

        if (weight >= boyMonth.max) {
          return "tooHigh";
        } else if (weight >= boyMonth.sd1High && weight < boyMonth.max) {
          return "high";
        } else if (weight <= boyMonth.min) {
          return "tooLow";
        } else if (weight <= boyMonth.sd1Low && weight > boyMonth.min) {
          return "low";
        } else {
          return "normal";
        }

      } else {
        if (months > 72) {
          girlMonth = this.allWeightDataGirl[71]
        } else {
          girlMonth = this.allWeightDataGirl.find((core) => self.parseIntFromString(core.yearOld) === months);
        }
        if (weight >= girlMonth.max) {
          return "tooHigh";
        } else if (weight >= girlMonth.sd1High && weight < girlMonth.max) {
          return "high";
        } else if (weight <= girlMonth.min) {
          return "tooLow";
        } else if (weight <= girlMonth.sd1Low && weight > girlMonth.min) {
          return "low";
        } else {
          return "normal";
        }
      }

    },

    // Lấy ra cân nặng ở bảng allWeightData để so sánh
    getFromAllHeightData(birthDay, measureDay, gender, height) {
      if (height === 0) {
        return "Chưa đo"
      }
      const months = this.getMonthNumber(birthDay, measureDay)
      let boyMonth = {};
      let girlMonth = {};
      let self = this;
      if (gender === "Nam") {
        if (months > 72) {
          boyMonth = this.allHeightDataBoy[71];
        } else {
          boyMonth = this.allHeightDataBoy.find((core) => self.parseIntFromString(core.yearOld) === months);
        }

        if (height >= boyMonth.max) {
          return "tooHigh";
        } else if (height >= boyMonth.sd1High && height < boyMonth.max) {
          return "high";
        } else if (height <= boyMonth.min) {
          return "tooLow";
        } else if (height <= boyMonth.sd1Low && height > boyMonth.min) {
          return "low";
        } else {
          return "normal";
        }

      } else {
        if (months > 72) {
          girlMonth = this.allHeightDataGirl[71]
        } else {
          girlMonth = this.allHeightDataGirl.find((core) => self.parseIntFromString(core.yearOld) === months);
        }

        if (height >= girlMonth.max) {
          return "tooHigh";
        } else if (height >= girlMonth.sd1High && height < girlMonth.max) {
          return "high";
        } else if (height <= girlMonth.min) {
          return "tooLow";
        } else if (height <= girlMonth.sd1Low && height > girlMonth.min) {
          return "low";
        } else {
          return "normal";
        }
      }
    },

    // Tính chỉ số BMI
    calculateBMI(height, weight) {
      if (height === 0 || weight === 0) {
        return 0;
      }
      return (weight / Math.pow(height / 100, 2)).toFixed(2);
    },

    // dữ liệu được map lên bảng của Excel
    mapDataForTable(newDataList) {
      this.targetList = newDataList.map((x, index) => [
        index + 1,
        x.fullName,
        moment(x.birthDay).format("DD/MM/YYYY"),
        x.measureDay !== "0000-00-00" ? this.calculateYearsAndMonths(x.birthDay, x.measureDay) : 'Chưa đo',
        x.gender,
        x.weight,
        x.height,
        this.calculateBMI(x.height, x.weight),
        x.measureDay !== "0000-00-00" ? moment(x.measureDay).format("DD/MM/YYYY") : 'Chưa đo',
        this.getFromAllWeightData(x.birthDay, x.measureDay, x.gender, x.weight) === 'tooLow' ? 'x' : '',
        this.getFromAllWeightData(x.birthDay, x.measureDay, x.gender, x.weight) === 'low' ? 'x' : '',
        this.getFromAllWeightData(x.birthDay, x.measureDay, x.gender, x.weight) === 'normal' ? 'x' : '',
        this.getFromAllWeightData(x.birthDay, x.measureDay, x.gender, x.weight) === 'high' ? 'x' : '',
        this.getFromAllWeightData(x.birthDay, x.measureDay, x.gender, x.weight) === 'tooHigh' ? 'x' : '',

        this.getFromAllHeightData(x.birthDay, x.measureDay, x.gender, x.height) === 'tooLow' ? 'x' : '',
        this.getFromAllHeightData(x.birthDay, x.measureDay, x.gender, x.height) === 'low' ? 'x' : '',
        this.getFromAllHeightData(x.birthDay, x.measureDay, x.gender, x.height) === 'normal' ? 'x' : '',
        this.getFromAllHeightData(x.birthDay, x.measureDay, x.gender, x.height) === 'high' ? 'x' : '',
        this.getFromAllHeightData(x.birthDay, x.measureDay, x.gender, x.height) === 'tooHigh' ? 'x' : '',

        this.calculateMonthsOldBMI(x.birthDay, x.measureDay, this.calculateBMI(x.height, x.weight), x.gender) === 'tooLow' ? 'x' : '',
        this.calculateMonthsOldBMI(x.birthDay, x.measureDay, this.calculateBMI(x.height, x.weight), x.gender) === 'low' ? 'x' : '',
        this.calculateMonthsOldBMI(x.birthDay, x.measureDay, this.calculateBMI(x.height, x.weight), x.gender) === 'normal' ? 'x' : '',
        this.calculateMonthsOldBMI(x.birthDay, x.measureDay, this.calculateBMI(x.height, x.weight), x.gender) === 'high' ? 'x' : '',
        this.calculateMonthsOldBMI(x.birthDay, x.measureDay, this.calculateBMI(x.height, x.weight), x.gender) === 'tooHigh' ? 'x' : '',

      ]);
    },

    mapDataForTableHistory(newDataList) {
      let self = this;
      for (let i = 0; i < newDataList.length; i++) {
        newDataList[i].dataList = newDataList[i].dataList.map((x, index) => [
          index + 1,
          moment(x.timeHeight).format("DD/MM/YYYY"),
          self.calculateYearsAndMonths(newDataList[i].birthDay, x.timeHeight),
          x.weight,
          x.height,
          self.calculateBMI(x.height, x.weight),
          self.getFromAllWeightData(newDataList[i].birthDay, x.timeHeight, newDataList[i].gender, x.weight) === 'tooLow' ? 'x' : '',
          self.getFromAllWeightData(newDataList[i].birthDay, x.timeHeight, newDataList[i].gender, x.weight) === 'low' ? 'x' : '',
          self.getFromAllWeightData(newDataList[i].birthDay, x.timeHeight, newDataList[i].gender, x.weight) === 'normal' ? 'x' : '',
          self.getFromAllWeightData(newDataList[i].birthDay, x.timeHeight, newDataList[i].gender, x.weight) === 'high' ? 'x' : '',
          self.getFromAllWeightData(newDataList[i].birthDay, x.timeHeight, newDataList[i].gender, x.weight) === 'tooHigh' ? 'x' : '',

          self.getFromAllHeightData(newDataList[i].birthDay, x.timeHeight, newDataList[i].gender, x.height) === 'tooLow' ? 'x' : '',
          self.getFromAllHeightData(newDataList[i].birthDay, x.timeHeight, newDataList[i].gender, x.height) === 'low' ? 'x' : '',
          self.getFromAllHeightData(newDataList[i].birthDay, x.timeHeight, newDataList[i].gender, x.height) === 'normal' ? 'x' : '',
          self.getFromAllHeightData(newDataList[i].birthDay, x.timeHeight, newDataList[i].gender, x.height) === 'high' ? 'x' : '',
          self.getFromAllHeightData(newDataList[i].birthDay, x.timeHeight, newDataList[i].gender, x.height) === 'tooHigh' ? 'x' : '',

          self.calculateMonthsOldBMI(newDataList[i].birthDay, x.timeHeight, self.calculateBMI(x.height, x.weight), newDataList[i].gender) === 'tooLow' ? 'x' : '',
          self.calculateMonthsOldBMI(newDataList[i].birthDay, x.timeHeight, self.calculateBMI(x.height, x.weight), newDataList[i].gender) === 'low' ? 'x' : '',
          self.calculateMonthsOldBMI(newDataList[i].birthDay, x.timeHeight, self.calculateBMI(x.height, x.weight), newDataList[i].gender) === 'normal' ? 'x' : '',
          self.calculateMonthsOldBMI(newDataList[i].birthDay, x.timeHeight, self.calculateBMI(x.height, x.weight), newDataList[i].gender) === 'high' ? 'x' : '',
          self.calculateMonthsOldBMI(newDataList[i].birthDay, x.timeHeight, self.calculateBMI(x.height, x.weight), newDataList[i].gender) === 'tooHigh' ? 'x' : '',
        ]);
      }
      this.kidsHeightWeightHistoryList = newDataList;
    },

    async exportToExcelCurrent() {
      if (this.multipleSelection.length < 1) {
        this.$message({
          message: "Bạn chưa chọn học sinh",
          type: "error",
        });
        return
      }
      this.$confirm(
          " Bạn có chắc chắn muốn xuất file hiện tại?",
          "Thông báo!",
          {
            confirmButtonText: "Có",
            cancelButtonText: "Không",
          }
      ).then(async () => {
        this.loadingButton = true;
        this.loadingData = true;
        await this.getKidsFromMultipleClick().then(() => {
          let completeExportToExcelData;
          if (!this.selectMonthToExportExcel) {
            completeExportToExcelData = {
              ...this.exportToExcelData,
              companyInfo: this.schoolInfoList,
              dataList: this.targetList,
              className: "Tên lớp: " + this.dataClass()
            }
          } else {
            completeExportToExcelData = {
              ...this.exportToExcelData,
              companyInfo: this.schoolInfoList,
              dataList: this.targetList,
              className: "Tên lớp: " + this.dataClass() + " (Thống kê 1-" + this.searchDateForExport.startDate + " đến 1-" + this.searchDateForExport.endDate + ")"
            }
          }
          this.exportToExcel(completeExportToExcelData)
        })
      }).finally(() => {
        this.$refs.multipleTable.clearSelection();
        this.multipleSelection = [];
        this.targetList = [];
        this.loadingData = false;
        this.loadingButton = false;
        this.selectMonthToExportExcel = false;
      })

    },

    //Lấy toàn bộ dữ liệu bảng sample_height và sample_weight
    async findAllHeightWeightData() {
      try {
        const resp = await KidsHeightWeightService.findAllHeightWeightDataFromServe();
        this.allHeightData = resp.data.data[0];
        this.allWeightData = resp.data.data[1];
        this.allHeightDataBoy = this.allHeightData.filter((x) => x.type === "boy");
        this.allHeightDataGirl = this.allHeightData.filter((x) => x.type === "girl");
        this.allWeightDataBoy = this.allWeightData.filter((x) => x.type === "boy");
        this.allWeightDataGirl = this.allWeightData.filter((x) => x.type === "girl");
      } catch (err) {
        console.error(err);
      }
    },
    async getKidsFromMultipleClick() {
      const self = this;
      let abc = this.multipleSelection;
      let abc123 = [];
      for (let i = 0; i < abc.length; i++) {
        let kidDetails = await self.getDetailKidsMethod(abc[i].id);
        let kid = new KidsHeightWeight(
            abc[i].id,
            abc[i].fullName,
            abc[i].gender,
            kidDetails[0], // height
            kidDetails[1], // weight
            abc[i].birthDay,
            kidDetails[2], // ngày cân gần nhất
        );
        abc123.push(kid);
      }
      this.mapDataForTable(abc123)
    },
    // Lấy trong lịch sử ngày cân gần nhất
    async getDetailKidsMethod(id) {
      let resp = await KidsHeightWeightService.getById(id);
      let data = resp.data.data;
      if (this.selectMonthToExportExcel === false) {
        this.kidsWeightList = data.kidsWeightList.sort((a, b) => {
          let timeHeightA = moment(a.timeWeight).toDate();
          let timeHeightB = moment(b.timeWeight).toDate();
          return timeHeightA - timeHeightB;
        });
        this.kidsHeightList = data.kidsHeightList.sort((a, b) => {
          let timeHeightA = moment(a.timeHeight).toDate();
          let timeHeightB = moment(b.timeHeight).toDate();
          return timeHeightA - timeHeightB;
        });
      } else {
        let startDate = moment(this.searchDateForExport.startDate, 'M-yyyy').toDate();
        let endDate = moment(this.searchDateForExport.endDate, 'M-yyyy').toDate();
        this.kidsHeightList = data.kidsHeightList.filter((x) => {
          let timeHeight = moment(x.timeHeight).toDate();
          return timeHeight >= startDate && timeHeight <= endDate;
        }).sort((a, b) => {
          let timeHeightA = moment(a.timeHeight).toDate();
          let timeHeightB = moment(b.timeHeight).toDate();
          return timeHeightA - timeHeightB;
        });
        this.kidsWeightList = data.kidsWeightList.filter((x) => {
          let timeWeight = moment(x.timeWeight).toDate();
          return timeWeight >= startDate && timeWeight <= endDate;
        }).sort((a, b) => {
          let timeWeightA = moment(a.timeWeight).toDate();
          let timeWeightB = moment(b.timeWeight).toDate();
          return timeWeightA - timeWeightB;
        });

      }

      if (this.kidsHeightList.length === 0 || this.kidsWeightList.length === 0) {
        return [0, 0, "0000-00-00"]
      } else {
        return [
          this.kidsHeightList[this.kidsHeightList.length - 1].height,
          this.kidsWeightList[this.kidsWeightList.length - 1].weight,
          this.kidsHeightList[this.kidsHeightList.length - 1].timeHeight,
        ];
      }
    },
    async getDataHeightWeightFromHistory() {
      let selectedKids = this.multipleSelection;
      let dataHeightList = [];
      let dataWeightList = [];
      let danhSachHocSinh = [];
      let danhSachHocSinh2 = [];
      for (let i = 0; i < selectedKids.length; i++) {
        const resp = await KidsHeightWeightService.getById(selectedKids[i].id);
        const data = resp.data.data;
        dataHeightList = data.kidsHeightList;
        dataWeightList = data.kidsWeightList;
        danhSachHocSinh = dataHeightList.map(hocSinh => {
          const {weight} = dataWeightList.find(x => x.fullName === hocSinh.fullName) || {};
          return {...hocSinh, weight,};
        });
        let a = {
          id: selectedKids[i].id,
          fullName: selectedKids[i].fullName,
          birthDay: selectedKids[i].birthDay,
          gender: selectedKids[i].gender,
          dataList: danhSachHocSinh
        }
        danhSachHocSinh2.push(a)
      }
      this.mapDataForTableHistory(danhSachHocSinh2)
    },
    async exportExcelStatisticalToSheet() {
      if (this.multipleSelection.length < 1) {
        this.$message({
          message: "Bạn chưa chọn học sinh",
          type: "error",
        });
        return
      }
      this.$confirm(
          " Bạn có chắc chắn muốn xuất file hiện tại?",
          "Thông báo!",
          {
            confirmButtonText: "Có",
            cancelButtonText: "Không",
          }
      ).then(async () => {
        this.loadingButton = true;
        this.loadingData = true;
        const exportToExcelData = {
          titleFile: 'LỊCH SỬ ĐÁNH GIÁ CHIỀU CAO, CÂN NẶNG',
          columnHeaders:
              ['STT', 'Ngày cân đo', 'Tuổi(tính đến ngày cân)', 'Cân nặng(kg)', 'Chiều cao(cm)', 'Chỉ số BMI',
                'Suy dinh dưỡng nặng', 'Suy dinh dưỡng', 'Trẻ cân nặng bình thường', 'Trẻ thừa cân', 'Cân nặng ở mức béo phì',
                'Thấp còi mức độ 2', 'Thấp còi mức độ 1', 'Chiều cao bình thường', 'Cao hơn so với tuổi', 'Chiều cao vượt trội',
                'Suy dinh dưỡng', 'Thiếu cân', 'Bình thường', 'Thừa cân', 'Béo phì'],
          columnWidths: [5, 12, 15, 6, 6, 6, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10], // Chiều rộng của từng cột
          nameFile: 'LỊCH_SỬ_ĐÁNH_GIÁ_CHIỀU_CAO_CÂN_NẶNG_HỌC_SINH',
          companyInfo: [...this.schoolInfo, "Lớp: " + this.dataClass()]
        }
        await this.getDataHeightWeightFromHistory().then(()=>{
           this.exportExcelToSheet(exportToExcelData, this.kidsHeightWeightHistoryList)
        })

      }).finally(() => {
        this.$refs.multipleTable.clearSelection();
        this.multipleSelection = [];
        this.loadingData = false;
        this.loadingButton = false;
        this.kidsHeightWeightHistoryList = [];
      })

    }
  },

  beforeMount() {
    this.getCurrentDate();
    this.findAllHeightWeightData();
  },
};
</script>
<style lang="scss" scoped>
.date {
  margin-bottom: 8px;
}

.button-select {
  margin-right: 5px;
  width: 150px;
}

/deep/ .el-table th {
  padding: 10px 0;
}

.paging-click {
  float: left;
  margin-top: 5px;
}

/deep/ .el-tabs__nav-scroll {
  float: right;
}

/deep/ [data-v-5ed4c166] .el-tabs--card > .el-tabs__header {
  border-bottom: none;
  margin-top: -8px;
}

/deep/ .el-tabs__nav-wrap {
  margin-top: 0px;
  margin-bottom: -15px;
}

/deep/ .el-tabs__nav {
  background: #a0a19ce0;
}

/deep/ .el-tabs__item {
  color: white;
}

/deep/ .el-icon-loading:before {
  content: "\e6cf";
}

/deep/ .el-loading-spinner .el-loading-text {
  color: #606266;
  font-size: 15px;
}

/deep/ .el-loading-spinner {
  top: 67%;
  width: 100%;
  text-align: center;
  position: absolute;
}

/deep/ .el-tabs__item.is-active {
  color: white;
  background: #78a5e7;
}

.btt {
  float: right;
  margin-top: 10px;
  // background-color: #4CAF50;
}

/deep/ .el-table th > .cell {
  text-align: center !important;
}

.buttonxemchitiet {
  background-color: #78a5e7;
}

.button-over {
  margin-right: 5px;
  border-radius: 0;
}

/deep/ .date[data-v-5ed4c166] {
  margin-bottom: -25px;
  z-index: 2;
  position: relative;
}

/deep/ .el-tabs--card > .el-tabs__header {
  border-bottom: none;
}

.content .row-data {
  margin-bottom: 20px;
}

.table-content {
  .el-table {
    border-top-left-radius: 5px;
    border: 1px solid #78a5e7;
  }
}

.button-left-class {
  margin-right: 5px;
  width: 150px;
}

.button-left-status {
  margin-right: 5px;
  width: 110px;
}

.button-left-class1 {
  margin-right: 5px;
  width: 133px;
}

.button-left-class0 {
  margin-right: 5px;
  width: 150px;
}

.button-left-class9 {
  margin-right: 5px;
  width: 150px;
}

.button-left-class2 {
  margin-right: -50px;
  width: 140px;
}

.button-left-class3 {
  margin-left: -10px;
  width: 140px;
}

.el-input-group {
  line-height: normal;
  display: inline-table;
  width: 100%;
  // border-collapse: separate;
  border-spacing: 0;
  margin-top: 5px;
}

/deep/ .el-table td,
/deep/ .el-table th {
  padding: 6px 0;
}

.paging-click {
  .el-pagination {
    text-align: right;
    margin: 30px 0;
  }
}

/deep/ .el-input__inner1998 {
  border: 1px solid #ffffff;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom: none;
}

/deep/ .el-input-group {
  line-height: normal;
  display: inline-table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  /* border: red; */
}

/deep/ .el-table th.gutter {
  background-color: #78a5e7;
}

.el-dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0;
  margin: 3px 0;
  background-color: rgb(95, 180, 74);
  border: 1px solid #ebeef5;
  border-radius: 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.el-dropdown-menu li {
  color: white;
  border-top: 1px solid white;
  font-family: Arial, Helvetica, sans-serif;
}
</style>
