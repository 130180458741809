var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Xem chiều cao, cân nặng: " + _vm.kidsHeightWeight.fullName,
        visible: _vm.dialogVisible,
        width: "1100px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "2vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Lịch sử cân đo", name: "KidWeightDetail" } },
            [
              _c(
                "div",
                { staticClass: "table-content row-data" },
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "table-icon-1",
                      attrs: {
                        data: _vm.kidsWeightList,
                        "highlight-current-row": "",
                        "header-cell-style": _vm.tableHeaderColor,
                        height: _vm.$tableMaxHeight,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          sortable: "",
                          label: "Lần cân",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "weight",
                          label: "Cân nặng (kg)",
                          width: "120",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          sortable: "",
                          label: "Ngày cân",
                          width: "110",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(scope.row.timeWeight)
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createdBy",
                          label: "Người cân",
                          align: "left",
                        },
                      }),
                      _vm.checkPermission([
                        "studentQuality_heightWeight_update",
                      ])
                        ? _c("el-table-column", {
                            attrs: {
                              label: "Tác vụ",
                              width: "70",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDeleteWeight(
                                                scope.$index,
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Xóa")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1344785868
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      staticClass: "table-icon-2",
                      attrs: {
                        data: _vm.kidsHeightList,
                        "highlight-current-row": "",
                        "header-cell-style": _vm.tableHeaderColor,
                        height: _vm.$tableMaxHeight,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "Lần đo",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "height",
                          label: "Chiều cao (cm)",
                          width: "120",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          sortable: "",
                          label: "Ngày đo",
                          width: "110",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(scope.row.timeHeight)
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createdBy",
                          label: "Người đo",
                          align: "left",
                        },
                      }),
                      _vm.checkPermission([
                        "studentQuality_heightWeight_update",
                      ])
                        ? _c("el-table-column", {
                            attrs: {
                              label: "Tác vụ",
                              width: "70",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDeleteHeight(
                                                scope.$index,
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Xóa")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3456588051
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Bảng tiêu chuẩn", name: "StandardBoard" } },
            [
              _c(
                "div",
                { staticClass: "table-content row-data" },
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "table-icon-3",
                      attrs: {
                        data: _vm.Listdata,
                        "highlight-current-row": "",
                        "header-cell-style": _vm.tableHeaderColor,
                        height: _vm.$tableMaxHeight,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          sortable: "",
                          label: "STT",
                          width: "70",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "yearOld",
                          label: "Độ tuổi",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "minW",
                          label: "Cân nặng cận dưới (kg)",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "mediumW",
                          label: "Cân nặng trung bình (kg)",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "maxW",
                          label: "Cân nặng cận trên (kg)",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "minH",
                          label: "Chiều cao cận dưới (cm)",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "mediumH",
                          label: "Chiều cao trung bình (cm)",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "maxH",
                          label: "Chiều cao cận trên (cm)",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }