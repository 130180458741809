var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-bottom": "-20px",
            position: "relative",
            "z-index": "1",
          },
        },
        [
          _vm.showAdd
            ? _c(
                "div",
                [
                  _c("el-date-picker", {
                    staticClass: "button-left-class1",
                    attrs: {
                      type: "date",
                      clearable: false,
                      format: "dd/MM/yyyy",
                      disabled: _vm.checkDisable,
                      "value-format": "yyyy-MM-dd",
                      placeholder: "Chọn ngày",
                      "picker-options": _vm.pickerOptions,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.getAllKidHeightWeight("date")
                      },
                    },
                    model: {
                      value: _vm.dataAddSearch.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataAddSearch, "date", $$v)
                      },
                      expression: "dataAddSearch.date",
                    },
                  }),
                  _c(
                    "el-select",
                    {
                      staticClass: "button-left-status",
                      attrs: { placeholder: "Trạng thái" },
                      on: {
                        change: function ($event) {
                          return _vm.getAllKidHeightWeight()
                        },
                      },
                      model: {
                        value: _vm.dataAddSearch.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataAddSearch, "status", $$v)
                        },
                        expression: "dataAddSearch.status",
                      },
                    },
                    _vm._l(_vm.kidStatusList, function (item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { value: item.key, label: item.value },
                      })
                    }),
                    1
                  ),
                  _vm.getAppTypeUserLogin == "plus"
                    ? _c(
                        "el-select",
                        {
                          staticClass: "input-common input-grade",
                          staticStyle: { width: "140px" },
                          attrs: { placeholder: "Chọn khối" },
                          on: {
                            change: function ($event) {
                              return _vm.changeGradeDetail()
                            },
                          },
                          model: {
                            value: _vm.dataAddSearch.idGrade,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataAddSearch, "idGrade", $$v)
                            },
                            expression: "dataAddSearch.idGrade",
                          },
                        },
                        _vm._l(_vm.gradeOfSchoolList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { value: item.id, label: item.gradeName },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-select",
                    {
                      staticClass: "input-common input-class",
                      staticStyle: {
                        width: "140px",
                        "margin-left": "5px",
                        "margin-right": "5px",
                      },
                      attrs: { placeholder: "Chọn lớp" },
                      on: {
                        change: function ($event) {
                          return _vm.getAllKidHeightWeight("date")
                        },
                      },
                      model: {
                        value: _vm.dataAddSearch.idClass,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataAddSearch, "idClass", $$v)
                        },
                        expression: "dataAddSearch.idClass",
                      },
                    },
                    _vm._l(_vm.classList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { value: item.id, label: item.className },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-input",
                    {
                      staticClass: "button-left-class",
                      staticStyle: { width: "220px" },
                      attrs: { placeholder: "Nhập tên", clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.getAllKidHeightWeight()
                        },
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.getAllKidHeightWeight()
                        },
                      },
                      model: {
                        value: _vm.dataAddSearch.codeOrName,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataAddSearch, "codeOrName", $$v)
                        },
                        expression: "dataAddSearch.codeOrName",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getAllKidHeightWeight()
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.showHistory
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-bottom": "-20px",
                    position: "relative",
                    "z-index": "1",
                  },
                },
                [
                  _c(
                    "el-date-picker",
                    {
                      staticClass: "button-left-class1",
                      attrs: {
                        type: "date",
                        disabled: _vm.checkDisable,
                        placeholder: "Chọn ngày",
                        "picker-options": _vm.pickerOptions,
                      },
                      model: {
                        value: _vm.selectDate,
                        callback: function ($$v) {
                          _vm.selectDate = $$v
                        },
                        expression: "selectDate",
                      },
                    },
                    [_vm._v("\n        >\n      ")]
                  ),
                  _c(
                    "el-select",
                    {
                      staticClass: "button-left-status",
                      attrs: { placeholder: "Trạng thái" },
                      on: {
                        change: function ($event) {
                          return _vm.getAllKidHeightWeightHistory()
                        },
                      },
                      model: {
                        value: _vm.dataSearch.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "status", $$v)
                        },
                        expression: "dataSearch.status",
                      },
                    },
                    _vm._l(_vm.kidStatusList, function (item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { value: item.key, label: item.value },
                      })
                    }),
                    1
                  ),
                  _vm.getAppTypeUserLogin == "plus"
                    ? _c(
                        "el-select",
                        {
                          staticClass: "input-common input-grade",
                          staticStyle: { width: "140px" },
                          attrs: { placeholder: "Chọn khối" },
                          on: {
                            change: function ($event) {
                              return _vm.changeGradeDetailH()
                            },
                          },
                          model: {
                            value: _vm.dataSearch.idGrade,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataSearch, "idGrade", $$v)
                            },
                            expression: "dataSearch.idGrade",
                          },
                        },
                        _vm._l(_vm.gradeOfSchoolList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { value: item.id, label: item.gradeName },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-select",
                    {
                      staticClass: "input-common input-class",
                      staticStyle: {
                        width: "140px",
                        "margin-left": "5px",
                        "margin-right": "5px",
                      },
                      attrs: { placeholder: "Chọn lớp" },
                      on: {
                        change: function ($event) {
                          return _vm.getAllKidHeightWeightHistory()
                        },
                      },
                      model: {
                        value: _vm.dataSearch.idClass,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "idClass", $$v)
                        },
                        expression: "dataSearch.idClass",
                      },
                    },
                    _vm._l(_vm.classList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { value: item.id, label: item.className },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-input",
                    {
                      staticClass: "button-left-clas",
                      staticStyle: { width: "220px" },
                      attrs: { placeholder: "Nhập mã hoặc tên", clearable: "" },
                      on: {
                        change: function ($event) {
                          return _vm.getAllKidHeightWeightHistory()
                        },
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.getAllKidHeightWeightHistory()
                        },
                      },
                      model: {
                        value: _vm.dataSearch.codeOrName,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "codeOrName", $$v)
                        },
                        expression: "dataSearch.codeOrName",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getAllKidHeightWeightHistory()
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "lblthemlichsu" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleClickTab },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "Thêm cân đo", name: "AddHeightWeight" } },
                [
                  _c(
                    "div",
                    { staticClass: "table-content row-data" },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadingData,
                              expression: "loadingData",
                            },
                          ],
                          ref: "multipleTable",
                          attrs: {
                            "empty-text": _vm.textTable,
                            "element-loading-text": _vm.$tableLoading,
                            "element-loading-spinner": "el-icon-loading",
                            "element-loading-background":
                              "rgba(255,255,255, 0)",
                            data: _vm.AddHeightWeightList,
                            "highlight-current-row": "",
                            "header-cell-style": _vm.tableHeaderColor,
                            "max-height": _vm.$tableMaxHeight,
                            border: "",
                          },
                          on: { "selection-change": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              type: "selection",
                              width: "55",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "STT",
                              width: "50",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "fullName",
                              label: "Họ và tên",
                              align: "left",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Ngày sinh",
                              width: "140",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            scope.row.birthDay
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "weight",
                              label: "Cân nặng ",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "60px",
                                        border: "1px solid #ffffff !important",
                                      },
                                      attrs: {
                                        placeholder: ".......",
                                        value: "",
                                      },
                                      model: {
                                        value: scope.row.weight,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "weight", $$v)
                                        },
                                        expression: "scope.row.weight",
                                      },
                                    }),
                                    _vm._v("      Kg\n              "),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Ngày cân ", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-date-picker", {
                                      staticClass: "button-left-class3",
                                      attrs: {
                                        type: "date",
                                        align: "center",
                                        placeholder: "Chọn ngày",
                                        "value-format": "yyyy-MM-dd",
                                        format: "dd/MM/yyyy",
                                        "picker-options": _vm.pickerOptions,
                                      },
                                      model: {
                                        value: scope.row.timeWeight,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "timeWeight", $$v)
                                        },
                                        expression: "scope.row.timeWeight",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "height",
                              label: "Chiều cao",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      staticClass: "button-left-class0",
                                      staticStyle: { width: "60px" },
                                      attrs: { placeholder: "......." },
                                      model: {
                                        value: scope.row.height,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "height", $$v)
                                        },
                                        expression: "scope.row.height",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                Cm\n              "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Ngày đo ", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-date-picker", {
                                      staticClass: "button-left-class9",
                                      attrs: {
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                        "value-format": "yyyy-MM-dd",
                                        format: "dd/MM/yyyy",
                                        "picker-options": _vm.pickerOptions,
                                      },
                                      model: {
                                        value: scope.row.timeHeight,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "timeHeight", $$v)
                                        },
                                        expression: "scope.row.timeHeight",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Tác vụ",
                              width: "160",
                              align: "center",
                              fixed: "right",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.checkPermission([
                                      "studentQuality_heightWeight_update",
                                    ])
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "success",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleSave(scope.row)
                                              },
                                            },
                                          },
                                          [_vm._v("Lưu\n                ")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleViewDetail(
                                              scope.$index,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Chi tiết\n                ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "btt" },
                        [
                          _c(
                            "el-dropdown",
                            { on: { command: _vm.handleCommandHeightWeight } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "button-over",
                                  attrs: {
                                    type: "success",
                                    loading: _vm.loadingButton,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                Xuất file Excel đánh giá   \n                "
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-caret-bottom",
                                  }),
                                ]
                              ),
                              _c(
                                "el-dropdown-menu",
                                { staticClass: "el-dropdown-menu-container" },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: {
                                        command: "exportExcelRecentTimes",
                                      },
                                    },
                                    [_vm._v("Xuất theo ngày cân mới nhất")]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: {
                                        command: "exportExcelSelectTime",
                                      },
                                    },
                                    [_vm._v("Xuất theo khoảng thời gian")]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: {
                                        command:
                                          "exportExcelStatisticalToSheet",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Thống kê đánh giá từng HS\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-dropdown",
                            { on: { command: _vm.handleCommand } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "button-over",
                                  attrs: { type: "success" },
                                },
                                [
                                  _vm._v(
                                    "\n                Tác vụ\n                "
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-caret-bottom",
                                  }),
                                ]
                              ),
                              _c(
                                "el-dropdown-menu",
                                { staticClass: "el-dropdown-menu-container" },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "exportExcelAll" } },
                                    [
                                      _vm._v(
                                        "Xuất các học sinh\n                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "exportExcelNew" } },
                                    [
                                      _vm._v(
                                        "Xuất mới nhất trong tháng\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "Lịch sử cân đo",
                    name: "HistoryHeightWeight",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "table-content row-data" },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadingData,
                              expression: "loadingData",
                            },
                          ],
                          attrs: {
                            "empty-text": _vm.textTable,
                            "element-loading-text": _vm.$tableLoading,
                            "element-loading-spinner": "el-icon-loading",
                            "element-loading-background":
                              "rgba(255,255,255, 0)",
                            data: _vm.kidsHeightWeightHistoryList,
                            "highlight-current-row": "",
                            "header-cell-style": _vm.tableHeaderColor,
                            "max-height": _vm.$tableMaxHeight,
                            border: "",
                          },
                          on: { "selection-change": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              type: "selection",
                              width: "55",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "STT",
                              width: "50",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "fullName",
                              label: "Họ và tên",
                              align: "left",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "weight",
                              label: "Cân nặng (Kg)",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "timeWeight",
                              label: "Ngày cân mới nhất",
                              width: "160",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "height",
                              label: "Chiều cao (Cm)",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "timeHeight",
                              label: "Ngày đo mới nhất",
                              width: "160",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Tác vụ",
                              width: "150",
                              align: "center",
                              fixed: "right",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "buttonxemchitiet",
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleViewDetail(
                                              scope.$index,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Chi tiết\n                ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "btt" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "button-over",
                              attrs: {
                                type: "success",
                                loading: _vm.loadingButton,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.exportExcelKidsHeightWeightNew()
                                },
                              },
                            },
                            [_vm._v("Xuất file Excel\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Vui lòng chọn tháng",
            visible: _vm.dialogVisibleSelectMonth,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleSelectMonth = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("el-date-picker", {
                staticStyle: { width: "200px" },
                attrs: {
                  format: "MM-yyyy",
                  type: "monthrange",
                  align: "right",
                  "unlink-panels": "",
                  "range-separator": "-",
                  "start-placeholder": "Tháng bắt đầu",
                  clearable: false,
                  "end-placeholder": "Tháng kết thúc",
                },
                on: { change: _vm.callDataSearch },
                model: {
                  value: _vm.monthAndYear,
                  callback: function ($$v) {
                    _vm.monthAndYear = $$v
                  },
                  expression: "monthAndYear",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.confirmSelectMonth } }, [
                _vm._v("OK"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.cancelSelectMonth },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("KidsHeightWeightDetail", {
        ref: "KidsHeightWeightDetail",
        attrs: { dialogVisible: _vm.showCreateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseShowMethod()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }