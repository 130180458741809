import ExcelJS from "exceljs";
import { saveAs } from 'file-saver';
import DrawExcel from "@/commonFuncion/DrawExcel";

export async function exportExcelToSheet(exportToExcelData, dataListTable) {

    const fileName = exportToExcelData.nameFile;
    const workbook = new ExcelJS.Workbook();

    for (let i = 0; i < dataListTable.length; i++) {
        const sheetName = dataListTable[i].fullName + '-' + dataListTable[i].id;
        await addDataList(workbook, sheetName, dataListTable[i].dataList, dataListTable[i].fullName);
    }

    async function addDataList(workbook, sheetName, data, name) {
        const titleFile = exportToExcelData.titleFile;
        const columnHeaders = exportToExcelData.columnHeaders;
        const companyInfo = exportToExcelData.companyInfo;
        const columnWidths = exportToExcelData.columnWidths;

        const worksheet = workbook.addWorksheet(sheetName);

        await DrawExcel.drawColumnWidths(columnWidths, worksheet);
        await DrawExcel.drawCompanyInfo(companyInfo, worksheet);
        await DrawExcel.drawTitleRow(titleFile, worksheet);
        await worksheet.addRow([]);


        const onTopOfTheTable1 = "Đánh giá cân nặng theo tháng tuổi";
        const onTopOfTheTable2 = "Đánh giá chiều cao theo tháng tuổi";
        const onTopOfTheTable3 = "Đánh giá BMI theo tháng tuổi";
        const onTopOfTheTableRow = worksheet.getRow(7);
        onTopOfTheTableRow.font = {size: 12}

        // Merge các cột và fill màu cho cột
        worksheet.mergeCells('B7:F7');
        const classIndex = onTopOfTheTableRow.getCell('B');
        classIndex.value = name;
        classIndex.alignment = {horizontal: 'center', wrapText: true};
        classIndex.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'FFFFFF00'},// Mã màu ARGB cho màu vàng
        };

        worksheet.mergeCells('G7:K7');
        const top1 = onTopOfTheTableRow.getCell('G');
        top1.value = onTopOfTheTable1;
        top1.alignment = {horizontal: 'center', wrapText: true};
        top1.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: '66CC99'},
        };

        worksheet.mergeCells(`L7:P7`)
        const top2 = onTopOfTheTableRow.getCell('L');
        top2.value = onTopOfTheTable2;
        top2.alignment = {horizontal: 'center', wrapText: true};
        top2.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: '00FFFF'},
        };

        worksheet.mergeCells(`Q7:U7`)
        const top3 = onTopOfTheTableRow.getCell('Q');
        top3.value = onTopOfTheTable3;
        top3.alignment = {horizontal: 'center', wrapText: true};
        top3.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'CCFFCC'},
        };
        await DrawExcel.drawTableHeaders(columnHeaders, worksheet);

        if (data.length === 0) {
            worksheet.mergeCells(`A9:U9`);
            const a = worksheet.getRow(9);
            const onBottom = a.getCell("A");
            onBottom.value = "Không có dữ liệu chiều cao, cân nặng đầy đủ hoặc chưa đo lần nào";
            onBottom.alignment = {horizontal: 'center', wrapText: true};
            onBottom.font = {
                color: {argb: 'FFFF0000'},
                size: 13,
            };
        } else {
            data.forEach((x) => worksheet.addRow(x));
        }

        // Đặt border cho toàn bộ bảng
        for (let i = 7; i <= data.length + 8; i++) {
            const row = worksheet.getRow(i);
            row.alignment = {horizontal: 'center', wrapText: true};
            row.eachCell({includeEmpty: true}, (cell) => {
                // Đặt border cho cell
                cell.border = {
                    top: {style: 'thin'},
                    left: {style: 'thin'},
                    bottom: {style: 'thin'},
                    right: {style: 'thin'},
                };
            });
        }
    }

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx');

}

