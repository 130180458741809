// Hàm này xuất ra 1 file có dạng header là 1 dòng tiu đề phụ trên đầu của bảng chính
import ExcelJS from "exceljs";
import { saveAs } from 'file-saver';
import DrawExcel from "@/commonFuncion/DrawExcel";
import {styleExcel} from "@/utils/styleExcel";

export async function exportToExcel(exportToExcelData) {

    const titleFile = exportToExcelData.titleFile;
    const columnHeaders = exportToExcelData.columnHeaders;
    const companyInfo = exportToExcelData.companyInfo;
    const columnWidths = exportToExcelData.columnWidths;
    const dataList = exportToExcelData.dataList;
    const fileName = exportToExcelData.nameFile;
    const classInSchool = exportToExcelData.className;

    const workbook = new ExcelJS.Workbook();
    const worksheet = await workbook.addWorksheet(fileName);

    await DrawExcel.drawColumnWidths(columnWidths, worksheet);
    await DrawExcel.drawCompanyInfo(companyInfo, worksheet);
    await DrawExcel.drawTitleRow(titleFile, worksheet);
    await worksheet.addRow([]);

    const onTopOfTheTable1 = "Đánh giá cân nặng theo tháng tuổi";
    const onTopOfTheTable2 = "Đánh giá chiều cao theo tháng tuổi";
    const onTopOfTheTable3 = "Đánh giá BMI theo tháng tuổi";
    const onTopOfTheTableRow = worksheet.getRow(6);
    onTopOfTheTableRow.font = {size: 12}

    // Merge các cột và fill màu cho cột
    worksheet.mergeCells('B6:I6');
    const classIndex = onTopOfTheTableRow.getCell('B');
    classIndex.value = classInSchool;
    classIndex.alignment = {horizontal: 'center', wrapText: true};
    classIndex.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FFFFFF00'},// Mã màu ARGB cho màu vàng
    };

    worksheet.mergeCells('J6:N6');
    const top1 = onTopOfTheTableRow.getCell('J');
    top1.value = onTopOfTheTable1;
    top1.alignment = {horizontal: 'center', wrapText: true};
    top1.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: '66CC99'},
    };

    worksheet.mergeCells(`O6:S6`)
    const top2 = onTopOfTheTableRow.getCell('O');
    top2.value = onTopOfTheTable2;
    top2.alignment = {horizontal: 'center', wrapText: true};
    top2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: '00FFFF'},
    };

    worksheet.mergeCells(`T6:X6`)
    const top3 = onTopOfTheTableRow.getCell('T');
    top3.value = onTopOfTheTable3;
    top3.alignment = {horizontal: 'center', wrapText: true};
    top3.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'CCFFCC'},
    };

    await DrawExcel.drawTableHeaders(columnHeaders, worksheet);
    dataList.forEach((x) => worksheet.addRow(x));
    // Đặt border cho toàn bộ bảng
    for (let i = 6; i <= dataList.length + 7; i++) {
        const row = worksheet.getRow(i);
        row.alignment = {horizontal: 'center', wrapText: true};
        row.eachCell({includeEmpty: true}, (cell) => {
            // Đặt border cho cell
            cell.border = styleExcel.borderTitle();
        });
    }

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx');
}