export const bmiBoy = [
    {month: 1, tooLow: 12.4, low: 13.6, normal: 14.9, high: 16.3, tooHigh:17.8},
    {month: 2, tooLow:13.7,  low: 15,   normal: 16.3, high: 17.8, tooHigh:19.4},
    {month: 3, tooLow:14.3,  low: 15.5, normal: 16.9, high: 18.4, tooHigh:20},
    {month: 4, tooLow:14.5,  low: 15.8, normal: 17.2, high: 18.7, tooHigh:20.3},
    {month: 5, tooLow:14.7,  low: 15.9, normal: 17.3, high: 18.8, tooHigh:20.5},
    {month: 6, tooLow:14.7,  low: 16,   normal: 17.3, high: 18.8, tooHigh:20.5},
    {month: 7, tooLow:14.8,  low: 16,   normal: 17.3, high: 18.8, tooHigh:20.5},
    {month: 8, tooLow:14.7,  low: 15.9, normal: 17.3, high: 18.7, tooHigh:20.4},
    {month: 9, tooLow:14.7,  low: 15.8, normal: 17.2, high: 18.6, tooHigh:20.3},
    {month: 10,tooLow:14.6,  low: 15.7, normal: 17,   high: 18.5, tooHigh:20.1},
    {month: 11,tooLow:14.5,  low: 15.6, normal: 16.9, high: 18.4, tooHigh:20},
    {month: 12,tooLow:14.4,  low: 15.5, normal: 16.8, high: 18.2, tooHigh:19.8},
    {month: 13,tooLow:14.3,  low: 15.4, normal: 16.7, high: 18.1, tooHigh:19.7},
    {month: 14,tooLow:14.2,  low: 15.3, normal: 16.6, high: 18,   tooHigh:19.5},
    {month: 15,tooLow:14.1,  low: 15.2, normal: 16.4, high: 17.8, tooHigh:19.4},
    {month: 16,tooLow:14,    low:15.1,  normal: 16.3, high: 17.7, tooHigh:19.3},
    {month: 17,tooLow:13.9,  low: 15,   normal: 16.2, high: 17.6, tooHigh:19.1},
    {month: 18,tooLow:13.9,  low: 14.9, normal: 16.1, high: 17.5, tooHigh:19},
    {month: 19,tooLow:13.8,  low: 14.9, normal: 16.1, high: 17.4, tooHigh:18.9},
    {month: 20,tooLow:13.7,  low: 14.8, normal: 16,   high: 17.3, tooHigh:18.8},
    {month: 21,tooLow:13.7,  low: 14.7, normal: 15.9, high: 17.2, tooHigh:18.7},
    {month: 22,tooLow:13.6,  low: 14.7, normal: 15.8, high: 17.2, tooHigh:18.7},
    {month: 23,tooLow:13.6,  low: 14.6, normal: 15.8, high: 17.1, tooHigh:18.6},
    {month: 24,tooLow:13.8,  low: 14.8, normal: 15.7, high: 17.3, tooHigh:18.9},
    {month: 25,tooLow:13.8,  low: 14.8, normal: 16,   high: 17.3, tooHigh:18.8},
    {month: 26,tooLow:13.7,  low: 14.8, normal: 15.9, high: 17.3, tooHigh:18.8},
    {month: 27,tooLow:13.7,  low: 14.7, normal: 15.9, high: 17.2, tooHigh:18.7},
    {month: 28,tooLow:13.6,  low: 14.7, normal: 15.9, high: 17.2, tooHigh:18.7},
    {month: 29,tooLow:13.6,  low: 14.7, normal: 15.8, high: 17.1, tooHigh:18.6},
    {month: 30,tooLow:13.6,  low: 14.6, normal: 15.8, high: 17.1, tooHigh:18.6},
    {month: 31,tooLow:13.5,  low: 14.6, normal: 15.8, high: 17.1, tooHigh:18.5},
    {month: 32,tooLow:13.5,  low: 14.6, normal: 15.7, high: 17,   tooHigh:18.5},
    {month: 33,tooLow:13.5,  low: 14.5, normal: 15.7, high: 17,   tooHigh:18.5},
    {month: 34,tooLow:13.4,  low: 14.5, normal: 15.7, high: 17,   tooHigh:18.4},
    {month: 35,tooLow:13.4,  low: 14.5, normal: 15.6, high: 16.9, tooHigh:18.4},
    {month: 36,tooLow:13.4,  low: 14.4, normal: 15.6, high: 16.9, tooHigh:18.4},
    {month: 37,tooLow:13.3,  low: 14.4, normal: 15.6, high: 16.9, tooHigh:18.3},
    {month: 38,tooLow:13.3,  low: 14.4, normal: 15.5, high: 16.8, tooHigh:18.3},
    {month: 39,tooLow:13.3,  low: 14.3, normal: 15.5, high: 16.8, tooHigh:18.3},
    {month: 40,tooLow:13.2,  low: 14.3, normal: 15.5, high: 16.8, tooHigh:18.2},
    {month: 41,tooLow:13.2,  low: 14.3, normal: 15.5, high: 16.8, tooHigh:18.2},
    {month: 42,tooLow:13.2,  low: 14.3, normal: 15.4, high: 16.8, tooHigh:18.2},
    {month: 43,tooLow:13.2,  low: 14.2, normal: 15.4, high: 16.7, tooHigh:18.2},
    {month: 44,tooLow:13.1,  low: 14.2, normal: 15.4, high: 16.7, tooHigh:18.2},
    {month: 45,tooLow:13.1,  low: 14.2, normal: 15.4, high: 16.7, tooHigh:18.2},
    {month: 46,tooLow:13.1,  low: 14.2, normal: 15.4, high: 16.7, tooHigh:18.2},
    {month: 47,tooLow:13.1,  low: 14.2, normal: 15.3, high: 16.7, tooHigh:18.2},
    {month: 48,tooLow:13.1,  low: 14.1, normal: 15.3, high: 16.7, tooHigh:18.2},
    {month: 49,tooLow:13,    low: 14.1, normal: 15.3, high: 16.7, tooHigh:18.2},
    {month: 50,tooLow:13,    low: 14.1, normal: 15.3, high: 16.7, tooHigh:18.2},
    {month: 51,tooLow:13,    low: 14.1, normal: 15.3, high: 16.6, tooHigh:18.2},
    {month: 52,tooLow:13,    low: 14.1, normal: 15.3, high: 16.6, tooHigh:18.2},
    {month: 53,tooLow:13,    low: 14.1, normal: 15.3, high: 16.6, tooHigh:18.2},
    {month: 54,tooLow:13,    low: 14,   normal: 15.3, high: 16.6, tooHigh:18.2},
    {month: 55,tooLow:13,    low: 14,   normal: 15.2, high: 16.6, tooHigh:18.2},
    {month: 56,tooLow:12.9,  low: 14,   normal: 15.2, high: 16.6, tooHigh:18.2},
    {month: 57,tooLow:12.9,  low: 14,   normal: 15.2, high: 16.6, tooHigh:18.2},
    {month: 58,tooLow:12.9,  low: 14,   normal: 15.2, high: 16.6, tooHigh:18.3},
    {month: 59,tooLow:12.9,  low: 14,   normal: 15.2, high: 16.6, tooHigh:18.3},
    {month: 60,tooLow:12.9,  low: 14,   normal: 15.2, high: 16.6, tooHigh:18.3},
    {month: 61,tooLow:13,    low: 14.1, normal: 15.3, high: 16.6, tooHigh:18.3},
    {month: 62,tooLow:13,    low: 14.1, normal: 15.3, high: 16.6, tooHigh:18.3},
    {month: 63,tooLow:13,    low: 14.1, normal: 15.3, high: 16.7, tooHigh:18.3},
    {month: 64,tooLow:13,    low: 14.1, normal: 15.3, high: 16.7, tooHigh:18.3},
    {month: 65,tooLow:13,    low: 14.1, normal: 15.3, high: 16.7, tooHigh:18.3},
    {month: 66,tooLow:13,    low: 14.1, normal: 15.3, high: 16.7, tooHigh:18.4},
    {month: 67,tooLow:13,    low: 14.1, normal: 15.3, high: 16.7, tooHigh:18.4},
    {month: 68,tooLow:13,    low: 14.1, normal: 15.3, high: 16.7, tooHigh:18.4},
    {month: 69,tooLow:13,    low: 14.1, normal: 15.3, high: 16.7, tooHigh:18.4},
    {month: 70,tooLow:13,    low: 14.1, normal: 15.3, high: 16.7, tooHigh:18.5},
    {month: 71,tooLow:13,    low: 14.1, normal: 15.3, high: 16.7, tooHigh:18.5},
    {month: 72,tooLow:13,    low: 14.1, normal: 15.3, high: 16.8, tooHigh:18.5}
]

export const bmiGirl = [
    {month: 1, tooLow:12,    low: 13.2, normal: 14.6, high: 16,   tooHigh: 17.5},
    {month: 2, tooLow:13,    low: 14.3, normal: 15.8, high: 17.3, tooHigh: 19},
    {month: 3, tooLow:13.6,  low: 14.9, normal: 16.4, high: 17.9, tooHigh: 19.7},
    {month: 4, tooLow:13.9,  low: 15.2, normal: 16.7, high: 18.3, tooHigh: 20},
    {month: 5, tooLow:14.1,  low: 15.4, normal: 16.8, high: 18.4, tooHigh: 20.2},
    {month: 6, tooLow:14.1,  low: 15.5, normal: 16.9, high: 18.5, tooHigh: 20.3},
    {month: 7, tooLow:14.2,  low: 15.5, normal: 16.9, high: 18.5, tooHigh: 20.3},
    {month: 8, tooLow:14.1,  low: 15.4, normal: 16.8, high: 18.4, tooHigh: 20.2},
    {month: 9, tooLow:14.1,  low: 15.3, normal: 16.7, high: 18.3, tooHigh: 20.1},
    {month: 10,tooLow:14,    low: 15.2, normal: 16.6, high: 18.2, tooHigh: 19.9},
    {month: 11,tooLow:13.9,  low: 15.1, normal: 16.5, high: 18,   tooHigh: 19.8},
    {month: 12,tooLow:13.8,  low: 15,   normal: 16.4, high: 17.9, tooHigh: 19.6},
    {month: 13,tooLow:13.7,  low: 14.9, normal: 16.2, high: 17.7, tooHigh: 19.5},
    {month: 14,tooLow:13.6,  low: 14.8, normal: 16.1, high: 17.6, tooHigh: 19.3},
    {month: 15,tooLow:13.5,  low: 14.7, normal: 16,   high: 17.5, tooHigh: 19.2},
    {month: 16,tooLow:13.5,  low: 14.6, normal: 15.9, high: 17.4, tooHigh: 19.1},
    {month: 17,tooLow:13.4,  low: 14.5, normal: 15.8, high: 17.3, tooHigh: 18.9},
    {month: 18,tooLow:13.3,  low: 14.4, normal: 15.7, high: 17.2, tooHigh: 18.8},
    {month: 19,tooLow:13.3,  low: 14.4, normal: 15.7, high: 17.1, tooHigh: 18.8},
    {month: 20,tooLow:13.2,  low: 14.3, normal: 15.6, high: 17,   tooHigh: 18.7},
    {month: 21,tooLow:13.2,  low: 14.3, normal: 15.5, high: 17,   tooHigh: 18.6},
    {month: 22,tooLow:13.1,  low: 14.2, normal: 15.5, high: 16.9, tooHigh: 18.5},
    {month: 23,tooLow:13.1,  low: 14.2, normal: 15.4, high: 16.9, tooHigh: 18.5},
    {month: 24,tooLow:13.3,  low: 14.4, normal: 15.7, high: 17.1, tooHigh: 18.7},
    {month: 25,tooLow:13.3,  low: 14.4, normal: 15.7, high: 17.1, tooHigh: 18.7},
    {month: 26,tooLow:13.3,  low: 14.4, normal: 15.6, high: 17,   tooHigh: 18.7},
    {month: 27,tooLow:13.3,  low: 14.4, normal: 15.6, high: 17,   tooHigh: 18.6},
    {month: 28,tooLow:13.3,  low: 14.3, normal: 15.6, high: 17,   tooHigh: 18.6},
    {month: 29,tooLow:13.2,  low: 14.3, normal: 15.6, high: 17,   tooHigh: 18.6},
    {month: 30,tooLow:13.2,  low: 14.3, normal: 15.5, high: 16.9, tooHigh: 18.5},
    {month: 31,tooLow:13.2,  low: 14.3, normal: 15.5, high: 16.9, tooHigh: 18.5},
    {month: 32,tooLow:13.2,  low: 14.3, normal: 15.5, high: 16.9, tooHigh: 18.5},
    {month: 33,tooLow:13.1,  low: 14.2, normal: 15.5, high: 16.9, tooHigh: 18.5},
    {month: 34,tooLow:13.1,  low: 14.2, normal: 15.4, high: 16.8, tooHigh: 18.5},
    {month: 35,tooLow:13.1,  low: 14.2, normal: 15.4, high: 16.8, tooHigh: 18.4},
    {month: 36,tooLow:13.1,  low: 14.2, normal: 15.4, high: 16.8, tooHigh: 18.4},
    {month: 37,tooLow:13.1,  low: 14.1, normal: 15.4, high: 16.8, tooHigh: 18.4},
    {month: 38,tooLow:13,    low: 14.1, normal: 15.4, high: 16.8, tooHigh: 18.4},
    {month: 39,tooLow:13,    low: 14.1, normal: 15.3, high: 16.8, tooHigh: 18.4},
    {month: 40,tooLow:13,    low: 14.1, normal: 15.3, high: 16.8, tooHigh: 18.4},
    {month: 41,tooLow:13,    low: 14.1, normal: 15.3, high: 16.8, tooHigh: 18.4},
    {month: 42,tooLow:12.9,  low: 14,   normal: 15.3, high: 16.8, tooHigh: 18.4},
    {month: 43,tooLow:12.9,  low: 14,   normal: 15.3, high: 16.8, tooHigh: 18.4},
    {month: 44,tooLow:12.9,  low: 14,   normal: 15.3, high: 16.8, tooHigh: 18.5},
    {month: 45,tooLow:12.9,  low: 14,   normal: 15.3, high: 16.8, tooHigh: 18.5},
    {month: 46,tooLow:12.9,  low: 14,   normal: 15.3, high: 16.8, tooHigh: 18.5},
    {month: 47,tooLow:12.8,  low: 14,   normal: 15.3, high: 16.8, tooHigh: 18.5},
    {month: 48,tooLow:12.8,  low: 14,   normal: 15.3, high: 16.8, tooHigh: 18.5},
    {month: 49,tooLow:12.8,  low: 13.9, normal: 15.3, high: 16.8, tooHigh: 18.5},
    {month: 50,tooLow:12.8,  low: 13.9, normal: 15.3, high: 16.8, tooHigh: 18.6},
    {month: 51,tooLow:12.8,  low: 13.9, normal: 15.3, high: 16.8, tooHigh: 18.6},
    {month: 52,tooLow:12.8,  low: 13.9, normal: 15.2, high: 16.8, tooHigh: 18.6},
    {month: 53,tooLow:12.7,  low: 13.9, normal: 15.3, high: 16.8, tooHigh: 18.6},
    {month: 54,tooLow:12.7,  low: 13.9, normal: 15.3, high: 16.8, tooHigh: 18.7},
    {month: 55,tooLow:12.7,  low: 13.9, normal: 15.3, high: 16.8, tooHigh: 18.7},
    {month: 56,tooLow:12.7,  low: 13.9, normal: 15.3, high: 16.8, tooHigh: 18.7},
    {month: 57,tooLow:12.7,  low: 13.9, normal: 15.3, high: 16.9, tooHigh: 18.7},
    {month: 58,tooLow:12.7,  low: 13.9, normal: 15.3, high: 16.9, tooHigh: 18.8},
    {month: 59,tooLow:12.7,  low: 13.9, normal: 15.3, high: 16.9, tooHigh: 18.8},
    {month: 60,tooLow:12.7,  low: 13.9, normal: 15.3, high: 16.9, tooHigh: 18.8},
    {month: 61,tooLow:12.7,  low: 13.9, normal: 15.2, high: 16.9, tooHigh: 18.9},
    {month: 62,tooLow:12.7,  low: 13.9, normal: 15.2, high: 16.9, tooHigh: 18.9},
    {month: 63,tooLow:12.7,  low: 13.9, normal: 15.2, high: 16.9, tooHigh: 18.9},
    {month: 64,tooLow:12.7,  low: 13.9, normal: 15.2, high: 16.9, tooHigh: 18.9},
    {month: 65,tooLow:12.7,  low: 13.9, normal: 15.2, high: 16.9, tooHigh: 19},
    {month: 66,tooLow:12.7,  low: 13.9, normal: 15.2, high: 16.9, tooHigh: 19},
    {month: 67,tooLow:12.7,  low: 13.9, normal: 15.2, high: 16.9, tooHigh: 19},
    {month: 68,tooLow:12.7,  low: 13.9, normal: 15.3, high: 17,   tooHigh: 19.1},
    {month: 69,tooLow:12.7,  low: 13.9, normal: 15.3, high: 17,   tooHigh: 19.1},
    {month: 70,tooLow:12.7,  low: 13.9, normal: 15.3, high: 17,   tooHigh: 19.1},
    {month: 71,tooLow:12.7,  low: 13.9, normal: 15.3, high: 17,   tooHigh: 19.2},
    {month: 72,tooLow:12.7,  low: 13.9, normal: 15.3, high: 17,   tooHigh: 19.2}
]

